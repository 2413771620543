
<template>
  <TransitionGroup name="slide-fade">
    <section v-for="item, i in order_items" :key="i" class="item">
      <h4>
        <template v-if="item.quantity > 0">
          {{ item.index + 1}}.
        </template>
        <!-- {{ item.dish.name }} -->
      </h4>
      <div class="price">
        {{ as_eur(item.dish.price) }}
      </div>
      <template v-for="modifier, i in item.modifiers" :key="i">
        <fieldset :id="[item.dish_id, item.index, modifier.id]" class="modifier single" v-if="modifier.composition=='single'">
          <label v-for="component, i in modifier.components" :key="i">
            <input
              type="radio"
              :name="['mod', item.dish_id, item.index, modifier.id]"
              :value="component.id"
              @change="single(item.dish_id, item.index, modifier.id, component.id)"
              v-bind:checked="is_checked_single(item.dish_id, item.index, modifier.id, component.id)"
              />
            {{ component.name }}
            &middot; {{ as_eur(component.price) }}
          </label>
        </fieldset>
        <fieldset :id="[item.dish_id, item.index, modifier.id]" class="modifier multiple" v-if="modifier.composition=='multiple'">
          <label v-for="component, i in modifier.components" :key="i">
            <input
              type="checkbox"
              :value="component.id"
              @change="multiple(item.dish_id, item.index, modifier.id, component.id, $event.target.checked)"
              v-bind:checked="is_checked_multiple(item.dish_id, item.index, modifier.id, component.id)"
              />
            {{ component.name }}
            <small class="" v-if="component.additive_ids">
              Enthält:
              <template v-for="id in component.additive_ids" :key="id">{{additives[id].name}}</template>.
            </small>
            <small class="" v-if="component.allergen_ids">
              Allergene:
              <template v-for="id in component.allergen_ids" :key="id">{{allergens[id].name}}</template>.
            </small>
            &middot; {{ as_eur(component.price) }}
          </label>
        </fieldset>
      </template>
    </section>
  </TransitionGroup>
</template>

<script>

import ky from 'ky'
import store from '@/store'

window.store = store

export default {
  name: 'Modifers',
  props: {
    popover_id: Number
  },
  methods: {
    is_checked_single(dish_id, index, modifier_id, component_id) {
      var key = [dish_id, index, modifier_id]
      if ( ! (key in store.state.desire))
        return false
      return store.state.desire[[dish_id, index, modifier_id]] == component_id
    },
    is_checked_multiple(dish_id, index, modifier_id, component_id) {
      var key = [dish_id, index, modifier_id]
      if ( ! (key in store.state.desire))
        return false
      return store.state.desire[[dish_id, index, modifier_id]].includes(component_id)
    },
    single(dish_id, index, modifier_id, component_id) {
      ky.post(process.env.VUE_APP_API_URL + '/order/desire/' + this.$route.params.venue, {
        headers: {
          'session-token': localStorage.getItem('session token')
        },
        json: {
          dish_id: dish_id,
          index: index,
          modifier_id: modifier_id,
          component_id: component_id,
        }
      }).json().then(data => {
        store.commit('desire', data)
      })
    },
    multiple(dish_id, index, modifier_id, component_id, checked) {
      ky.post(process.env.VUE_APP_API_URL + '/order/desire/' + this.$route.params.venue, {
        headers: {
          'session-token': localStorage.getItem('session token')
        },
        json: {
          dish_id: dish_id,
          index: index,
          modifier_id: modifier_id,
          component_id: component_id,
          checked: checked
        }
      }).json().then(data => {
        store.commit('desire', data)
      })
    }
  },
  computed: {
    order_items () {
      var items = []
      for (var [dish_id, quantity] of Object.entries(this.$store.state.order)) {
        if (dish_id != this.popover_id) continue
        if ( ! (dish_id in this.$store.state.dishes)) continue
        var dish = this.$store.state.dishes[dish_id]
        if (dish.modifier_ids.length == 0) {
          continue;
        }
        var modifiers = []
        for (var modifier_id of dish.modifier_ids) {
          modifiers.push(this.$store.state.modifiers[modifier_id])
        }
        for (var i = 0; i < quantity; i++) {
          items.push({
            "dish_id": dish_id,
            "dish": dish,
            "modifiers": modifiers,
            "index": i,
            "quantity": quantity
          })
        }
      }
      return items
    },
    additives() {
      return store.state.additives
    },
    allergens() {
      return store.state.allergens
    }
  },
  components: {
  }
}
</script>

<style lang="scss">

section.item {
  fieldset {
    display: inline-block;
    border: none;

    label {
      border: 2px black solid;
      padding: 8px 16px 8px 16px;
      border-radius: 16px;
      margin: 4px;
      display: inline-block;
      cursor: pointer;
      user-select: none;
    }
  }
  margin: 0 auto;
}


</style>
