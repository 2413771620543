function isHomescreen() {
  return window.navigator.standalone || (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
}

function inappRequiresHomescreen() {
  let version = iosVersion()
  // very new ios
  if (version[0] > 16) return true

  // barely new ios
  if (version[0] == 16 && version[1] >= 4) return true

  // older ios- should use supportsInApp first so this doesn't happen
  // throw "invalid ios version for inapp"
  return null
}

function iosVersion() {
  // newer ios support inapp but require the app to be on the home screen
  let versionString = /(iPhone|iPad) OS ([1-9_]*)/g.exec(window.navigator.userAgent)?.[2]

  // not ios
  if ( ! versionString) return false

  let version = versionString.split('_').map(n => parseInt(n))
  return version
}

function supportsInapp() {
  return window.PushManager && window.ServiceWorker
}

export default {
  isHomescreen: isHomescreen,
  inappRequiresHomescreen: inappRequiresHomescreen,
  supportsInapp: supportsInapp
}


