import { createStore } from 'vuex'

export default createStore({
  state: {
    dishes: {},
    categories: {},
    brands: {},
    grouped: {},
    modifiers: {},
    allergens: {},
    additives: {},
    order: {},
    desire: {},
    discount: null,
    popover: null,
    my: {order: []},
    fulfillment: {location: null, container: null},
    method: null,
    venue: [],
  },
  mutations: {
    init(state, newState) {
      // should only be used for initializing the
      // dishes and other session-cachable state
      Object.assign(state, newState)
    },
    order(state, order) {
      state.order = order
    },
    desire(state, desire) {
      state.desire = desire
    },
    popover(state, popover_dish) {
      state.popover = popover_dish
    },
    my(state, my) {
      state.my = my
    },
    fulfillment(state, fulfillment) {
      state.fulfillment = fulfillment
    },
    method(state, method) {
      state.method = method
    },
    discount(state, method) {
      state.discount = method
    },
    venue(state, venue) {
      state.venue = venue 
    }
  },
  actions: {
  },
  modules: {
  }
})
