
<template>
  <section class="brand" v-for="brand_id in sorted_brand_ids" :key="brand_id">
    <div class="brand-header">
      <!--
      <i>{{ brands[brand_id].slogan }}</i>
      -->
      <img v-if="brands[brand_id].logo != ''" class="brand" :src="base+'/static/'+brands[brand_id].logo"/>
      <b v-else>{{ brands[brand_id].name }}</b>
    </div>
    <div class="scroll-showcaser-heightsetter">
      <div class="scroll-showcaser"><div>❯</div></div>
      <div class="dishes-per-brand">
        <template v-for="(dish_ids, category_id) in grouped[brand_id]" :key="category_id">
          <template v-for="dish_id in dish_ids" :key="dish_id">
            <Dish :dish_id="dish_id" :dish="dishes[dish_id]" />
          </template>
        </template>
      </div>
    </div>
  </section>
  <div v-if="popover" id="dish-popover" class="popover" @scroll.prevent.stop="">
    <a href="" class="back" @click.prevent="this.$store.commit('popover', null)"><img src="../assets/back.svg" /></a>
    <h2>{{ popover.name }}</h2>
    <p>{{ popover.description }}</p>
    <small class="allergens-and-additives" v-if="false">
      <template v-if="popover.additive_ids">
        Enthält:
        <template v-for="name, id, i in popover_additives" :key="id">
          <template v-if="i != 0">,</template>
          {{ name }}
        </template>
      </template>
      <template v-else>
        Enthält keine Zusatzstoffe.
      </template>
      <br><br>
      <template v-if="popover.allergen_ids">
        Allergene:
        <template v-for="name, id, i in popover_allergens" :key="id">
          <template v-if="i != 0">,</template>
          {{ name }}
        </template>
      </template>
      <template v-else>
        Keine Allergene
      </template>
    </small>
    <p>{{ as_eur(popover.price) }}</p>
    <img :src="popover_image_src" id="popover-image" />
    <Quantity :dish_id="popover.id" />
    <Modifiers :popover_id="popover.id"/>
  </div>
</template>

<style type="scss">
  #dish-popover {
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    overflow-y: auto;
    box-sizing: border-box;
    padding-top: 60px;
    img {
      width: 300px;
      height: 300px;
    }
  }
  section.brand {
    position: relative;
  }
  .scroll-showcaser-heightsetter {
    position: relative;
  }
  .scroll-showcaser {
    width: 80px;
    position: absolute;
    right: 0;
    font-size: 40px;
    color: rgba(256,256,256,0.7);
    font-family: Zapf Dingbats, Mono, Sans;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: right;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.25));
    height: 100%;
  }
</style>

<script>

import Dish from './Dish.vue'
import Quantity from '@/components/Quantity.vue'
import Modifiers from '@/components/Modifiers.vue'

export default {
  name: 'Dishes',
  props: {
  },
  methods: {
  },
  computed: {
    categories () {
      return this.$store.state.categories
    },
    brands () {
      return this.$store.state.brands
    },
    dishes () {
      return this.$store.state.dishes
    },
    grouped () {
      return this.$store.state.grouped
    },
    sorted_brand_ids () {
      var pinned = []
      var unpinned = []
      for (const brand_id of Object.keys(this.$store.state.grouped)) {
        if (this.brands[brand_id].pin) {
          pinned.push(brand_id)
        } else {
          unpinned.push(brand_id)
        }
      }
      var sorted = pinned.concat(unpinned)
      return sorted
    },
    base () {
      return process.env.VUE_APP_API_URL;
    },
    popover () {
      var popover = this.$store.state.popover
      if (popover)
        document.body.classList.add("popover")
      else
        document.body.classList.remove("popover")
      return popover
    },
    popover_image_src () {
      if ( ! this.popover.image || this.popover.image.length == 0) return this.base + 'nopopover.png'
      return this.base + '/static/' + this.popover.image[0] 
    },
    popover_additives() {
      var a = {}
      for (let id of this.popover.additive_ids) {
        a[id] = this.$store.state.additives[id].name
      }
      return a
    },
    popover_allergens() {
      var a = {}
      for (let id of this.popover.allergen_ids) {
        a[id] = this.$store.state.allergens[id].name
      }
      return a
    }
  },
  components: {
    Dish, Quantity, Modifiers
  }
}
</script>

<style lang="scss">

body.popover {
  overflow: hidden;

  #popover-image {
    max-width: 500px;
    max-height: 500px;
  }
}

section.brand {

  img.brand {
    padding: 0 20px;
    display: block;
    /* height: 150px; */
    /* simple responsive brand logo */
    max-width: 100%;
    box-sizing: border-box;
  }

  .brand-header {
    b {
      font-size: 24px;
      margin-right: 4px;
    }
    background-color: #c8e2c8;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    justify-content: left;
  }

  .dishes-per-brand {

    display: flex;

    overflow-x: auto;


    margin-bottom: 2em;
  }

  margin: 0 0 2em 0;
}

small.allergens-and-additives {
  display: block;
  font-style: italic;
  margin: 1em auto;
}


</style>
