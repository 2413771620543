
<template>
  <router-link class="venue" v-if="current" :to="{name:'Switcher'}">
    <b>{{ current.name }}</b>
    <i>{{ current.street }}</i>
  </router-link>
</template>

<script>

import store from '@/store'

export default {
  name: 'Venue',
  methods: {
  },
  computed: {
    base () {
      return process.env.VUE_APP_API_URL
    },
    current () {
      return store.state.venue[this.$route.params.venue]
    }
  },
  components: {
  }
}
</script>

<style lang="scss">

.venue {
  display: block;
  left: 12px;
  top: 12px;
  position: absolute;
  text-align: left;
  b {
    display: block;
    font-size: 27px;
    text-transform: uppercase;
    color: white;
    background: black;
    padding: 2px 5px;
  }
  i {
    display: block;
    font-size: 21px;
    margin: 3px 2px;
  }
}

@media screen and (max-width: 400px) {
  .venue {
    position: relative;
    left: 0;
    top: -6px;
    align-self: normal;
  }
}

</style>


