import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Switcher from  '../views/Switcher.vue'

import { set_discount } from '@/order' 

const routes = [
  {
    path: '/',
    name: 'Switcher',
    component: Switcher
  },
  {
    path: '/:venue',
    name: 'Home',
    component: Home
  },
  {
    path: '/:venue/summary',
    name: 'Summary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "summary" */ '../views/Summary.vue')
  },
  {
    path: '/:venue/status',
    name: 'Status',
    component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue')
  },
  {
    path: '/:venue/my',
    name: 'My',
    component: () => import(/* webpackChunkName: "status" */ '../views/My.vue')
  },
  {
    path: '/d/:code',
    name: 'DiscountNoVenue',
    redirect: to => {
      set_discount(to.params.code)
      return { name : 'Switcher'}
    },
  },
  {
    path: '/d/:venue/:code',
    name: 'Discount',
    redirect: to => {
      set_discount(to.params.code)
      return {name:'Home',params:{venue:to.params.venue}}
    }
  },
  {
    path: '/debug',
    name: 'Debug',
    component: () => import(/* webpackChunkName: "status" */ '../views/Debug.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
