<template>
  <template v-if="terminal">
    <Terminal />
  </template>
  <router-view v-if="routeParamsValid" />
  <NotFound v-else />

</template>

<script>

import ky from 'ky'
import store from './store'

import { defineAsyncComponent } from 'vue'

var Terminal = null

if (process.env.VUE_APP_STRIPE_TERMINAL_ENABLE == '1') {
  Terminal = defineAsyncComponent(() =>
    import('@/components/Terminal.vue')
  )
}

var NotFound = defineAsyncComponent(() =>
  import('@/views/NotFound.vue')
)

if (process.env.VUE_APP_MOBILE_DEBUG_ENABLE == '1') {
  (function () { var script = document.createElement('script'); script.src="https://cdn.jsdelivr.net/npm/eruda"; document.body.append(script); script.onload = function () { window.eruda.init(); } })();
}

export default {

  beforeMount() {

    function reload_if_new_build() {
      ky.get('/index.html').text().then(index_html_contents => {
        var last_index_html_contents = localStorage.getItem('index.html contents')
        if (last_index_html_contents == null) {
          console.log('deploy auto reload', 'initializing last index.html state')
          localStorage.setItem('index.html contents', index_html_contents)
          return
        }
        if (index_html_contents != last_index_html_contents) {
          localStorage.setItem('index.html contents', index_html_contents)
          window.location.reload()
        } 
        console.log('deploy auto reload', 'no change in index.html detected')
      })
    }
    window.addEventListener('online', reload_if_new_build)
    window.addEventListener('visibilitychange', reload_if_new_build)

    function handle_error(e) {
      if (e.response.status == 403 || e.response.status == 401) {
        // There is probably a more nuanced way of doing things but this will do for now
        localStorage.clear()
        window.location.reload()
      }
    }

    var loadData = () => {
      // These are a lot of requests. We rely on HTTP/2 to multiplex all these
      // for us, and on async python (querying redis) to be lightweight, so we don't care to
      // put all these into one request for performance or anything like that

      if (this.$route.params.venue) {
        ky.get(process.env.VUE_APP_API_URL + '/dish/' + this.$route.params.venue).json().then(data => {
          function deepFreeze(object) {
            const propNames = Object.getOwnPropertyNames(object)
            for (const name of propNames) {
              const value = object[name]
              if (value && typeof value === "object") {
                deepFreeze(value)
              }
            }
            return Object.freeze(object)
          }
          deepFreeze(data)  // performance improvement for reactivity
          store.commit('init', data)
        }).catch(handle_error)
        ky.get(process.env.VUE_APP_API_URL + '/order/recent/' + this.$route.params.venue, {
          headers: {
            'session-token': localStorage.getItem('session token')
          }
        }).json().then(data => {
          store.commit('order', data)
        }).catch(handle_error)
        ky.get(process.env.VUE_APP_API_URL + '/order/desire/' + this.$route.params.venue, {
          headers: {
            'session-token': localStorage.getItem('session token')
          }
        }).json().then(data => {
          store.commit('desire', data)
        }).catch(handle_error)
      }

      ky.get(process.env.VUE_APP_API_URL + '/my/order', {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('my', {'order': data})
      }).catch(handle_error)
      ky.get(process.env.VUE_APP_API_URL + '/order/fulfillment', {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('fulfillment', data)
      }).catch(handle_error)
      ky.get(process.env.VUE_APP_API_URL + '/order/method', {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('method', data)
      }).catch(handle_error)
      ky.get(process.env.VUE_APP_API_URL + '/order/discount', {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('discount', data)
      }).catch(handle_error)
      ky.get(process.env.VUE_APP_API_URL + '/venue', {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('venue', data)
      }).catch(handle_error)
    }

    function routerInit() {
      if (! localStorage.getItem('session token')) {  // try to get the session token from sessionStorage and localStorage
        ky.post(process.env.VUE_APP_API_URL + '/order/init').json().then(data => {
          // we start out putting the new token in sessionStorage, this might be a public computer.
          // if an orderer decides to save payment details, then the session token is moved
          // to the more permanent localStorage
          localStorage.setItem('session token', data)
          loadData()
        })
      } else {
        loadData()
      }
    }

    this.$watch(() => this.$route.params.venue, routerInit)  // reload data on venue change
    routerInit() // initial load

  },
  beforeUnmount() {
  },              
  computed: {
    terminal() {
      return process.env.VUE_APP_STRIPE_TERMINAL_ENABLE == '1'
    },
    initialized() {
      return undefined !== this.$store.state.categories
    },
    routeParamsValid() {
      if ('venue' in this.$route.params) {
        if ( ! (this.$route.params.venue in store.state.venue)) {
          return false
        }
      }
      return true
    }
  },
  components: { Terminal, NotFound }
}

</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  border: 0;
}
a {
  &:link,
  &:hover,
  &:active,
  &:visited {
    color: black;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

a.button {
  border: 5px black solid;
  border-radius: 8px;
  background: white;
  font-weight: bold;
  font-size: 32px;
  padding: 12px 16px 8px 16px;
  &:link, &:hover, &:active, &:visited {
    color: black;
    text-decoration: none;
  }
  display: inline-block;

  &.call-to-action {
    position: fixed;
    bottom: 5px;
    align-self: center;
  }
}

a.back {
  text-decoration: none;
  display: block;
  position: fixed;
  top: 10px;
  left: 10px;

  img {
    width: 62px;
    height: 62px;
  }
}

a.my {
  text-decoration: none;
  display: block;
  position: absolute;
  top: 40px;
  right: 20px;
  img {
    width: 45px;
    height: 45px;
  }
  opacity: 0.3;
}

.logo {
  height:120px;
  position: fixed;
  top: 3px;
  align-self: center;
  pointer-events: none;
  z-index: 33;
}

.framer {
  display: flex;
  flex-direction: column;
  padding: 140px 0 80px 0;
}

/* standard transition */

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

input[type=text] {
  padding: 0.5em 0.25em;
  border: 1px #777 solid;
  font-size: 16px;
}

</style>
