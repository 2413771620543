<template>
  <template v-if=" ! isHomescreen">
    <div class="install-me" v-if="inappRequiresHomescreen">
      Mach’s dir einfach &amp; aktiviere Push Notifications:
      <div class="ios-install-instructions">
        <div class="row">
          <img class="ios share" src="/ios-share.svg"> Teilen auswählen
        </div>
        <div class="row">
          <img class="ios add" src="/ios-add.svg"> zum Home Screen hinzufügen
        </div>
        <div class="row">
          <img class="ios inline-logo" src="../assets/logo.svg">auf Icon klicken &amp; bestellen
        </div>
      </div>
      Wir schicken dir eine Benachrichtigung, sobald dein Essen abholbereit ist!
    </div>
  </template>
</template>

<script>
import detect from '@/detect.js'

export default {
  name: 'InstallMe',
  methods: {
  },
  computed: {
    isHomescreen() {
      return detect.isHomescreen()
    },
    supportsInapp() {
      return detect.supportsInapp()
    },
    inappRequiresHomescreen() {
      return detect.inappRequiresHomescreen()
    }
  },
  components: {
  }
}
</script>

<style lang="scss">

.install-me {
  margin: 0em 0.25em 0.25em 0.25em;
  border: 2px black dotted;
  padding: 0.5em 0.5em;
  font-size: 90%;
}

.ios-install-instructions {
  padding: 0.5em;
  .row {
    padding-bottom: 0.25em;
  }
}

span.arrow {
  display: inline-block;
  margin: 0 1em;
  font-size: 200%;
}

img.ios {
  width: 1.5em;
  margin-top: -0.75em;
  margin-bottom: -0.5em;
}

</style>


