<template>
  <div class="dish">
    <div class="dish-clickable" @click="popover(dish)">
      <h4>{{ dish.name }}</h4>
      <div class="price">
        {{ as_eur(dish.price) }}
      </div>
      <div class="select-modifiers" v-if="select_modifiers" style="padding-top: 1em; box-sizing: border-box;">
        <span class="modifier-teaser" v-for="modifier, i in first_dish_modifiers" :key="i">
          <template v-if="modifier.selected_components.length > 1">
            Mit: {{ modifier.selected_components[0]}}, …
          </template>
          <template v-else-if="modifier.selected_components.length == 1">
            Mit: {{ modifier.selected_components[0]}}
          </template>
          <template v-else>
            Ohne: {{ modifier.unselected_components[0]}}<template v-if="modifier.unselected_components.length > 1">…
            </template>
          </template>
        </span>
        <br>
        <small v-if="quantity > 1">
          {{ quantity - 1 }} weitere<br>
        </small>
        <br>
        <b>ändern</b>
      </div>
      <img v-else :src="image_src">
    </div>
    <Quantity :dish_id="dish_id" />
  </div>
</template>

<script>
import Quantity from '@/components/Quantity.vue'

export default {
  name: 'Dish',
  props: {
    dish_id: Number,
    dish: Object
  },
  methods: {
    popover (popover_dish) {
      this.$store.commit('popover', popover_dish)
    }
  },
  computed: {
    base () {
      return process.env.VUE_APP_API_URL + '/static/'
    },
    image_src () {
      if ( ! this.dish.image || this.dish.image.length == 0) return this.base + 'nodish.png'
      return this.base + this.dish.image[0] 
    },
    quantity() {
      return this.$store.state.order[this.dish_id] || 0
    },
    select_modifiers() {
      if (this.dish.modifier_ids.length == 0) return false
      if (this.quantity == 0) return false
      return true
    },
    first_dish_modifiers() {
      var modifiers = []
      for (var modifier_id of this.dish.modifier_ids) {
        var modifier = this.$store.state.modifiers[modifier_id]
        var selected_components = []
        var unselected_components = []
        for (var component of Object.values(modifier.components)) {
          unselected_components.push(component.name)
          var key = [this.dish_id, 0, modifier_id]
          if (key in this.$store.state.desire) {
            var value = this.$store.state.desire[key]
            if (Array.isArray(value) && value.includes(component.id) || value == component.id) {
              selected_components.push(component.name)
            }
          }
        }
        modifiers.push({
          selected_components: selected_components,
          unselected_components: unselected_components
        })        
      }
      return modifiers
    } 
  },
  components: {
    Quantity,
  }
}

</script>

<style lang="scss">

.dish {
  width: 200px;
  margin: 0 8px;
  img {
    max-height: 120px;
    max-width: 120px;
    margin: 0 -10px;
  }
  div.select-modifiers {
    width: 120px;
    height: 120px;
    border-radius: 7px;
    border: 2px solid;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    padding: 3px;
    box-sizing: border-box;
  }
  h4 {
    height: 80px;
  }
  p {
    height: 220px;
    width: 160px;
  }

  .price {
  }

}

</style>
