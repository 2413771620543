<template>
  <div class="switcher framer">
    <img class="logo" src="../assets/logo.svg">
    <Discount />

    <p>
      Bitte wählen Sie Ihren CloudEatery-Standort
    </p>

    <router-link class="venue button" v-for="v in venue" :key="v.short" :to="{name:'Home',params:{venue:v.short}}">
      <b>{{ v.name }}</b>
      <i>{{ v.street }}</i>
    </router-link>
  </div>

</template>

<script>
import store from '@/store'
import Discount from '@/components/Discount.vue'
export default {
  name: 'Switcher',
  data() {
  },
  methods: {
  },
  components: {
    Discount
  },
  computed: {
    venue() {
      var v = store.state.venue
      //delete v['ber1']
      //delete v['muc1']
      return v
    }
  }
}
</script>


<style lang="scss">

.switcher {
  a.button {
    padding: 0;
    margin: 3px;
  }
}

@media screen and (min-width: 401px) {
  .venue {
    position: static;
    max-width: 400px;
    align-self: center;
    cursor: pointer;
    text-decoration: none;
  }
}

</style>
