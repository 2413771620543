
export function has_modifiers(store) {
  for (var dish_id of Object.keys(store.state.order)) {
    if ( ! (dish_id in store.state.dishes)) continue // TODO: don't run at all until loaded
    var dish = store.state.dishes[dish_id]
    if (dish.modifier_ids.length > 0) {
      return true
    }
  }
  return false
}

import ky from 'ky'
import store from '@/store'
export function set_discount(code) {
  ky.post(process.env.VUE_APP_API_URL + '/order/discount/' + code, {
    headers: {
      'session-token': localStorage.getItem('session token')
    }
  }).json().then(data => {
    console.log(data)
    if (data == null) return
    store.commit('discount', data)
  })
}

export function apply_discount(dish, discount) {
  dish.discount = Math.round(dish.price * discount.percentage * 0.01)
  dish.discounted = dish.price - dish.discount
}

export function* order_iter(state) {
  function* component_iter(modifier, component_ids) {
    for (const component_id of component_ids) {
      var component = Object.assign({key: modifier.key.concat(component_id) }, modifier.components[component_id])
      if (state.discount) {
        apply_discount(component, state.discount)
      }
      yield component
    }
  }
  function* modifier_iter(dish, index) {
    for (var modifier_id of dish.modifier_ids) {
      var key = [dish.id, index, modifier_id]
      if ( ! (key in state.desire))
        continue
      var component_ids = state.desire[key]
      if ( ! Array.isArray(component_ids)) {
        component_ids = [component_ids]
      }
      var modifier = Object.assign({key: key}, state.modifiers[modifier_id])
      yield [modifier, component_iter(modifier, component_ids)]
    }
  }
  for (const [dish_id, quantity] of Object.entries(state.order)) {
    for (var index = 0; index < quantity; index++) {
      var dish = Object.assign({key: [dish_id, index]}, state.dishes[dish_id])
      if (state.discount) {
        apply_discount(dish, state.discount)
      }
      yield [dish, modifier_iter(dish, index), index]
    }
  }
}

