import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


var app = createApp(App)

// TODO: put these helpers in their own file

var intl = Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import './registerServiceWorker'
dayjs.locale('de')

app.config.globalProperties.as_eur = amount => {
  return intl.format(amount * 0.01) + " €"
}
app.config.globalProperties.as_date = date => {
  return dayjs(date).format('DD.MM.YYYY')
}

app.use(store).use(router).mount('#app')
