
<template>
  <div class="discount" v-if="name">
    {{ name }}
    {{ percentage }}%
    <a @click.prevent="clear" href=""><small>(entfernen)</small></a>
  </div>
</template>

<script>

import ky from 'ky'
import store from '@/store'

export default {
  name: 'Discount',
  methods: {
    clear() {
      ky.delete(process.env.VUE_APP_API_URL + '/order/discount', {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('discount', data)
      })
    }
  },
  computed: {
    base () {
      return process.env.VUE_APP_API_URL
    },
    name() {
      if (store.state.discount)
        return store.state.discount.name
      return null
    },
    percentage() {
      if (store.state.discount)
        return store.state.discount.percentage
      return null
    }
  },
  components: {
  }
}
</script>

<style lang="scss">

.discount {
  font-size: 14px;
  font-weight: bold;
  color: red;
  margin: 0px 0 8px 0;
}

</style>

