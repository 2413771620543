<template>
  <div class="home framer">
    <Discount />
    <Venue class="venue" />
    <img class="logo" src="../assets/logo.svg">
    <InstallMe />
    <Dishes />
    <span class="closed" v-if="! open">Wir sind bis Mittwoch 30.3. ausserplanmässig geschlossen und freuen uns, Sie danach wieder zu begrüssen</span>
    <router-link v-if="open" class="order button call-to-action" :to="{name:'Summary',params:{event: this.$route.name}}">Bestellen...</router-link>
    <router-link v-if="has_my" class="my" :to="{name:'My',params:{venue:this.$route.params.venue}}"><img src="../assets/my.svg" /></router-link>
  </div>
  <footer class="home" v-if="venue">
    {{venue.company}} &middot; {{venue.street}} &middot; {{ venue.postcode }} {{venue.city}} &middot; Öffnungszeiten: {{ venue.hours }} &middot; <a @click.prevent="toggleImprint" href="">Impressum</a>
    <div id="imprint" v-if="showImprint">
      <h3>Impressum</h3>
      
      <h4>CloudEatery GmbH</h4>
      
      app.cloudeatery.kitchen<br>
      esse.jetzt<br>

      <i>Geschäftsanschrift</i>

      <address>
        CloudEatery GmbH <br>
        Habsburgerallee 6<br>
        60385 Frankfurt am Main<br>
        Deutschland
      </address>

      <h4>Kontakt</h4>

      <table class="imprint">
        <tr><th>Email</th><td>{{ venue.email }}</td></tr>
        <tr><th>Telefon</th><td>{{ venue.phone }}</td></tr>
        <tr><th>Sitz</th><td>Frankfurt am Main, Germany</td></tr>
        <tr><th>Geschäftsführer</th><td>Matthias Schneider, Remo Gianfrancesco</td></tr>
        <tr><th>Registergericht</th><td>Amtsgericht Frankfurt am Main</td></tr>
        <tr><th>Handelsregisternummer</th><td>HRB 123510</td></tr>
        <tr><th>Umsatzsteuer-Identifikationsnummer</th><td>DE344390677</td></tr>
      </table>

      <p>Link zur Plattform für die Online-Streitbeilegung der Europäischen Kommission: <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a> CloudEatery GmbH ist nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

      <p>&copy; 2022 - {{ new Date().getFullYear() }}</p>
    </div>
  </footer>

</template>

<script>
// @ is an alias to /src
import Dishes from '@/components/Dishes.vue'
import Venue from '@/components/Venue.vue'
import Discount from '@/components/Discount.vue'
import InstallMe from '@/components/InstallMe.vue'
// import { has_modifiers } from '@/order'

import store from '@/store'

export default {
  name: 'Home',
  data() {
    return {
      showImprint: false,
    }
  },
  methods: {
    toggleImprint(e) {
      if (this.showImprint) {
        this.showImprint = false
        return
      }

      this.showImprint = true
      e.target.scrollIntoView(true)
    }
  },
  components: {
    Dishes, Discount, Venue, InstallMe
  },
  computed: {
    has_my() {
      if (process.env.VUE_APP_STRIPE_TERMINAL_ENABLE == '1') {
        // done this way so dead-code-removal will remove the entire if-block
        // for non-terminal user facing app
        return false
      }
      return true
    },
    open () {
      return true
    },
    venue() {
      return store.state.venue[this.$route.params.venue]
    }
  }
}
</script>


<style lang="scss">

footer.home {
  margin-bottom: 7em;
}


span.closed {
  font-weight: bold;
  position: fixed;
  background: white;
  padding: 4px;
  display: block;
  top: 5px;
  left: 5px;
}

#imprint {
  max-width: 800px;
  margin: 0 auto;
  table {
    width: 100%;
    th, td {
      text-align: left;
    }
  }
}

</style>
