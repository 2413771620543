
<template>
  <div class="quantity-control">
    <div class="up" @click="up"></div>
    <div class="quantity">{{ quantity }}</div>
    <div class="down" @click="down"></div>
  </div>
</template>

<script>

import ky from 'ky'
import store from '../store'

export default {
  name: 'Quantity',
  props: {
    dish_id: Number
  },
  methods: {
    up() {
      this.modify(1)
    },
    down() {
      this.modify(-1)
    },
    modify(quantity) {
      ky.post(process.env.VUE_APP_API_URL + '/order/modify/' + this.$route.params.venue, {
        json: {
          dish_id: this.dish_id,
          quantity: quantity
        },
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('order', data[0])
        store.commit('desire', data[1])
      })
    }
  },
  computed: {
    quantity() {
      return store.state.order[this.dish_id] || 0
    }
  }
}
</script>

<style lang="scss">
.quantity-control {
  display: flex;
  flex-direction: column;
  font-size: 200%;
  .up {
    cursor: pointer;
  }
  .up:before {
    content: "\25B2";
  }
  .down {
    cursor: pointer;
  }
  .down:before {
    content: "\25BC";
  }
  .quantity {
  }
  .quantity {
  }
}
button {
  margin: 0 0 2px 0;
}

.desires {
  div {
    border: 1px black solid;
    border-radius: 3px;
    margin: 3px;
    padding: 3px 1px 1px 1px;
    box-sizing: border-box;
    a {
      font-size: 10px;
      &:link,
      &:hover,
      &:active,
      &:visited {
        color: black;
      }
    }
    cursor: pointer;
  }
  margin-bottom: 8px;
  user-select: none;
}

.desires-enter-active,
.desires-leave-active {
  transition: opacity 1s;
}

.desires-enter,
.desires-leave-to {
  opacity: 0;
}

</style>

